import { Backdrop, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
// import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import paymentImage from "../../assets/Icons//Vector1.svg";
import ShipmentImage from "../../assets/Icons/la_shipping-fast.svg";
import CustomerInfoImage from "../../assets/Icons/Vector.svg";
import { DIRECT_ACCESS_URL } from "../../constants/constants";
import useFetch from "../../customHook/useFetch";
import { setCartCount } from "../../redux/cart";
import {
  setCheckoutFormSlice,
  setCheckoutOrderSummarySlice,
  setCheckoutSecondFormSlice,
  setCheckoutThirdFormSlice,
} from "../../redux/CheckoutForm/CheckoutFormSlice";
import styles from "../../scss/pageComponent/checkoutCart.module.scss";
import CheckboxGraph from "./CheckboxGraph";
import CheckoutFormDetail from "./CheckoutFormDetail";
import CheckoutShipmentForm from "./CheckoutShipmentForm";
import CheckoutPayment from "./CheckoutPayment";
import CheckoutOrderSummary from "./CheckoutOrderSummary";
import { Decrypt } from "../../common/utils/Functions";
const CheckoutCart = () => {
  const navigate = useNavigate();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCheckoutFormSlice({}));
    dispatch(setCheckoutSecondFormSlice({}));
    dispatch(setCheckoutOrderSummarySlice({}));
    dispatch(setCheckoutThirdFormSlice({}));
  }, []);

  let data = [
    {
      name: "Customer Information",
      img: CustomerInfoImage,
      competedImg: false,
      done: "false",
      value: 0,
    },
    {
      name: "Shipping Method",
      img: ShipmentImage,
      competedImg: false,
      done: "false",
      value: 1,
    },
    {
      name: "Payment",
      img: paymentImage,
      competedImg: false,
      done: "false",
      value: 2,
    },
  ];

  let summaryDataAll = {
    tax: "",
    tax_type: "",
    is_charge_processing_fee_type: "",
    Processing_fee: "",
    shipping_fee_type: "",
    shipping_amount: "",
    store_fees: "",
    payment_handle_type: "",
    hideTax: 1,
  };

  const customerInformation = useSelector(
    (state: any) => state.storeCheckoutForm.FirstFormSlice
  );
  const shippingMethod = useSelector(
    (state: any) => state.storeCheckoutForm.SecondFormSlice
  );
  // const paymentData = useSelector(
  //   (state: any) => state.storeCheckoutForm.ThirdFormSlice
  // );
  const orderSummary = useSelector(
    (state: any) => state.storeCheckoutForm.OrderSummary
  );
  // const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY || "");

  const cartDataInfoD = localStorage?.getItem("cart");
  const cartDataInfo = Decrypt(cartDataInfoD);
  const cartOldData = cartDataInfo ? JSON.parse(cartDataInfo) : [];
  const [bundleData, setBundleData] = useState(cartOldData);

  const formDataInSlice = useSelector((state: any) => state.storeCheckoutForm);
  const [selected, setSelected] = useState<any>(0);
  const [imageData, setImageData] = useState(data);

  const [idOfStore, setIDofStore] = useState("");
  const [storeDetail, setStoreDetail] = useState(null);
  const [orgId, setOrgId] = useState(0);
  const [summaryData, setSummaryData] = useState(summaryDataAll);

  const [isCollectTax, setIsCollectTax] = useState(false);

  const ClickTab = (e: any) => {
    // useSelected(e);

    if (e === 1) {
      if (formDataInSlice?.FirstFormSlice?.email) {
        setSelected(1);
      }
    } else if (e === 2) {
      if (ShowSpecificForm.ShippingForm === false) {
        if (formDataInSlice?.FirstFormSlice?.email) {
          setSelected(2);
        }
      } else if (formDataInSlice?.SecondFormSlice.firstName) {
        setSelected(2);
      }
    } else {
      setSelected(0);
    }
    // setSummaryData()
  };
  let ClickTabFromButton = (e: any) => {
    setSelected(e);
    if (e == 1) {
      imageData[0].competedImg = true;
      setImageData(imageData);
    } else if (e == 2) {
      imageData[0].competedImg = true;

      if (imageData.length > 2) {
        imageData[1].competedImg = true;
      }
      setImageData(imageData);
    } else if (e == 3) {
      imageData[0].competedImg = true;
      imageData[1].competedImg = true;
      imageData[2].competedImg = true;
    }
  };

  let formInfo = {
    customerForm: true,
    PaymentForm: true,
    ShippingForm: true,
  };

  const [showFullname, setshowFullname] = useState(true);
  const [collectBillingInfo, setCollectBillingInfo] = useState(true);
  const [paymentType, setPaymentType] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [custom_informations, setCustom_informations] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [ShowSpecificForm, setShowSpecificForm] = useState(formInfo);

  // const [wholeData, setWholeData] = useState();
  const [first, setfirst] = useState();

  const [InfoData, { response: getResponse, error }] = useFetch(
    `/get-store-info/${DIRECT_ACCESS_URL}`,
    {
      method: "get",
    }
  );

  const [
    orderCreate,
    {
      response: responseOrderCreate,
      loading: orderCreateLoading,
      error: orderCreateError,
    },
  ] = useFetch("/order-create", {
    method: "post",
  });

  const placeOrder = (
    fistStep: any = null,
    secondStep: any = null,
    paymentData: any = null,
    custom_info: any = null
  ) => {
    setIsLoading(true);
    const custom_question_answer: any = [];
    if (custom_info === null) {
      customerInformation.customeSave &&
        customerInformation.customeSave.map((data: any) => {
          custom_question_answer.push({
            question_id: data.id,
            answer: data.value.toString(),
          });
        });
    } else {
      custom_info &&
        custom_info.map((data: any) => {
          custom_question_answer.push({
            question_id: data.id,
            answer: data.value.toString(),
          });
        });
    }
    const productList: any = [];
    bundleData.map((val: any) => {
      if (val.isSingleProduct) {
        const productOption: any = [];
        val.store_options.map((option: any) => {
          const optionName = option.store_option_items.find(
            (optionData: any) => {
              if (optionData.id === option.selectedData) {
                return optionData.option_name.toString();
              }
            }
          );

          productOption.push({
            option_id: option.id,
            option_type: option.option_type,
            option_value:
              option.input_type === 1
                ? optionName
                  ? optionName.option_name.toString()
                  : ""
                : option.selectedData.toString(),
            option_name: option ? option?.display_name.toString() : "-",
          });
        });
        productList.push({
          bundle_id: 0,
          store_product_id: val.id,
          quantity: val.quantity ? val.quantity : 1,
          base_price: val.base_price ? val.base_price : 0,
          club_price: val.club_price ? val.club_price : 0,
          product_options: productOption,
        });
      } else {
        val.bundle_products.map((product: any) => {
          const productOption: any = [];
          if (
            product.store_product_detail.store_options &&
            product.store_product_detail.store_options.length > 0
          ) {
            product.store_product_detail.store_options.map((option: any) => {
              const optionName = option.store_option_items.find(
                (optionData: any) => {
                  if (optionData.id === option.selectedData) {
                    return optionData.option_name.toString();
                  }
                }
              );

              productOption.push({
                option_id: option.id,
                option_value:
                  option.input_type === 1
                    ? optionName
                      ? optionName.option_name.toString()
                      : ""
                    : option.selectedData.toString(),
                option_type: option.option_type,
                option_name: option ? option?.display_name.toString() : "-",
              });
            });
          }
          productList.push({
            bundle_id: product.bundle_id,
            store_product_id: product.store_product_detail.id,
            quantity: product.store_product_detail.quantity
              ? product.store_product_detail.quantity
              : 0,
            base_price: product.store_product_detail.base_price
              ? product.store_product_detail.base_price
              : 0,
            club_price: product.store_product_detail.club_price
              ? product.store_product_detail.club_price
              : 0,
            product_options: productOption,
          });
        });
      }
    });
    let check_billing_address = paymentData
      ? paymentData.checkbox
        ? 1
        : 0
      : 1;
    const request = {
      token: paymentData && paymentData.token !== "" ? paymentData.token : "",
      org_id: orderSummary.org_id ? orderSummary.org_id : 1,
      store_id: idOfStore,
      coupon_id: orderSummary.coupon_id ? orderSummary.coupon_id : "",
      coupon_code: orderSummary.coupon_code ? orderSummary.coupon_code : "",
      coupon_amount: orderSummary.coupon_amount
        ? orderSummary.coupon_amount
        : "0.00",
      total_amount: orderSummary.total_amount ? orderSummary.total_amount : 0,
      sub_total: orderSummary.sub_total ? orderSummary.sub_total : 0,
      sale_tax: orderSummary.sale_tax ? orderSummary.sale_tax : 0,
      processing_fee: orderSummary.processing_fee
        ? Number(orderSummary.sub_total) === 0
          ? 0
          : orderSummary.processing_fee
        : "",
      ship_to_home: orderSummary.ship_to_home ? orderSummary.ship_to_home : "",
      payment_type: "",
      status: 1,
      player_first_name: fistStep
        ? fistStep.firstName
        : customerInformation
          ? customerInformation.firstName
          : "",
      team_id: fistStep
        ? fistStep.teamId
        : customerInformation
          ? customerInformation.teamId
          : "",
      player_last_name: fistStep
        ? fistStep.lastName
        : customerInformation
          ? customerInformation.lastName
          : "",
      email:
        fistStep && fistStep.email
          ? fistStep.email
          : customerInformation.email || "",
      phone_number: fistStep
        ? fistStep.phone
        : customerInformation.phone
          ? customerInformation.phone
          : "",
      s_first_name: secondStep
        ? secondStep.firstName
        : shippingMethod.firstName
          ? shippingMethod.firstName
          : "",
      s_last_name: secondStep
        ? secondStep.lastName
        : shippingMethod.lastName
          ? shippingMethod.lastName
          : "",
      s_street: secondStep
        ? secondStep.street
        : shippingMethod.state
          ? shippingMethod.street
          : "",
      s_apt: secondStep
        ? secondStep.aptUnit
        : shippingMethod.aptUnit
          ? shippingMethod.aptUnit
          : "",
      s_country: shippingMethod && shippingMethod.firstName ? "USA" : "",
      s_city: secondStep
        ? secondStep.city
        : shippingMethod.city
          ? shippingMethod.city
          : "",
      s_state: secondStep
        ? secondStep.state.toString()
        : shippingMethod.state
          ? shippingMethod.state.toString()
          : "",
      s_zip: secondStep
        ? secondStep.zipCode
        : shippingMethod.zipCode
          ? shippingMethod.zipCode
          : "",
      // is_billing_address: 1,
      is_billing_address: check_billing_address,
      b_first_name:
        check_billing_address === 0 && paymentData && paymentData.firstName
          ? paymentData.firstName
          : "",
      b_last_name:
        check_billing_address === 0 && paymentData && paymentData.lastName
          ? paymentData.lastName
          : "",
      b_street:
        check_billing_address === 0 && paymentData && paymentData.street
          ? paymentData.street
          : "",
      b_apt:
        check_billing_address === 0 && paymentData && paymentData.aptUnit
          ? paymentData.aptUnit
          : "",
      b_country: "USA",
      b_city:
        check_billing_address === 0 && paymentData && paymentData.city
          ? paymentData.city
          : "",
      b_state:
        check_billing_address === 0 && paymentData && paymentData.state
          ? paymentData.state.toString()
          : "",
      b_zip:
        check_billing_address === 0 && paymentData && paymentData.zipCode
          ? paymentData.zipCode
          : "",
      payment_status: "",
      custom_question_answer: custom_question_answer,
      order_products: productList,
    };

    orderCreate(request);
  };

  useEffect(() => {
    if (responseOrderCreate?.status && responseOrderCreate?.status === 1) {
      const orderResponce = {
        orderId: responseOrderCreate.data.customer_order_id,
        email: responseOrderCreate.data.customer_email,
        receipt: responseOrderCreate.data.receipt,
        storeName: responseOrderCreate.data.store_name,
      };

      localStorage.removeItem("cart");
      dispatch(setCartCount(0));
      dispatch(setCheckoutFormSlice({}));
      setIsLoading(false);
      navigate("/thankyou", { state: { orderResponce } });
    } else {
      toast.error(orderCreateError?.message);
      if (orderCreateError?.status && orderCreateError?.status === 2) {
        localStorage.removeItem("cart");
        dispatch(setCartCount(0));
        dispatch(setCheckoutFormSlice({}));
        navigate("/");
        setIsLoading(false);
        return;
      }
      if (orderCreateError?.code?.toString() === "404") {
        localStorage.removeItem("cart");
        navigate("/");
      }
      setIsLoading(false);
    }
  }, [responseOrderCreate, orderCreateError]);

  useLayoutEffect(() => {
    InfoData();
  }, []);

  useLayoutEffect(() => {
    if (getResponse?.data) {
      setStoreDetail(getResponse?.data ?? null);
      setIDofStore(getResponse?.data?.id);
      setOrgId(getResponse?.data?.org_id ?? 0);
      setshowFullname(getResponse?.data?.is_name_collected);
      setCollectBillingInfo(getResponse?.data?.is_collect_billing_info);
      setPaymentType(getResponse?.data?.payment_handle_type);
      setCustom_informations(getResponse?.data?.custom_informations);
      // let objOfData: any = [
      //   { payment_handle_type: getResponse?.data?.payment_handle_type },
      //   { shipping_method_type: getResponse?.data?.shipping_method_type },
      // ];

      summaryDataAll = {
        tax:
          summaryData?.tax !== "" ? summaryData?.tax : getResponse?.data?.tax,
        tax_type: getResponse?.data?.tax_type,
        is_charge_processing_fee_type:
          getResponse?.data?.is_charge_processing_fee,
        Processing_fee: "",
        shipping_fee_type: getResponse?.data?.shipping_method_type,
        shipping_amount: getResponse?.data?.shipping_method_value,
        store_fees: getResponse?.data?.store_fees,
        payment_handle_type: getResponse?.data?.payment_handle_type,
        hideTax: getResponse?.data?.where_to_ship,
      };

      setSummaryData(summaryDataAll);
      setfirst(getResponse?.data);
      const cartDataInfoD = localStorage?.getItem("cart");
      const cartDataInfo = Decrypt(cartDataInfoD);
      const cartData = cartDataInfo ? JSON.parse(cartDataInfo) : [];
      const isSingleProduct = cartData?.find(
        (item: any) => item?.isSingleProduct
      );
      if (
        (getResponse?.data?.payment_handle_type === 2 &&
          getResponse?.data?.is_collect_billing_info === 0) ||
        (getResponse?.data?.payment_handle_type === 3 &&
          getResponse?.data?.is_collect_billing_info === 0 &&
          isSingleProduct === undefined &&
          grandTotal === 0) ||
        (grandTotal === 0 && getResponse?.data?.payment_handle_type !== 2)
      ) {
        data = data.filter(function (item) {
          return item.value !== 2;
        });
        setImageData([...data]);
        formInfo.PaymentForm = false;
        setShowSpecificForm(formInfo);
      } else {
        setImageData([...data]);
        formInfo.PaymentForm = true;
        setShowSpecificForm(formInfo);
      }

      if (
        getResponse?.data?.shipping_method_type === 0 &&
        ((getResponse?.data?.where_to_ship === 1 &&
          getResponse?.data?.tax_type !== 1) ||
          getResponse?.data?.where_to_ship === 0)
      ) {
        data = data.filter(function (item) {
          return item.value !== 1;
        });
        setImageData([...data]);
        formInfo.ShippingForm = false;
        setShowSpecificForm(formInfo);
      }

      setIsCollectTax(
        (getResponse?.data?.where_to_ship === 1 &&
          getResponse?.data?.tax_type === 1 &&
          (isSingleProduct !== undefined ||
            getResponse?.data?.payment_handle_type === 1)) ||
          formInfo?.PaymentForm === false
          ? true
          : false
      );
    }
  }, [getResponse, grandTotal]);

  useEffect(() => {
    const cartDataInfoD = localStorage?.getItem("cart");
    const cartDataInfo = Decrypt(cartDataInfoD);
    const cartData = cartDataInfo ? JSON.parse(cartDataInfo) : [];

    if (cartData.length <= 0) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (selected === 2) {
      if (grandTotal === 0) {
        setSelected(1);
      }
    }
  }, [grandTotal]);

  return (
    <>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <CheckboxGraph
            data={imageData}
            selected={setSelected}
            click={ClickTab}
            formSelect={selected}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <p className={styles.flowName}>{data[selected].name}</p>
              {selected === 0 && (
                <CheckoutFormDetail
                  storeDetail={storeDetail}
                  showFullname={showFullname}
                  custom_informations={custom_informations}
                  selected={ClickTabFromButton}
                  formInfo={formInfo}
                  first={first}
                  isLoading={isLoading}
                  ShowSpecificForm={ShowSpecificForm}
                  placeOrder={placeOrder}
                />
              )}
              {selected === 1 && (
                <CheckoutShipmentForm
                  selected={ClickTabFromButton}
                  formInfo={formInfo}
                  ShowSpecificForm={ShowSpecificForm}
                  isLoading={isLoading}
                  placeOrder={placeOrder}
                  setSummaryData={setSummaryData}
                  summaryData={summaryData}
                  isCollectTax={isCollectTax}
                />
              )}
              {selected === 2 && (
                // <Elements stripe={stripePromise}>
                <CheckoutPayment
                  idOfStore={idOfStore}
                  ShowSpecificForm={ShowSpecificForm}
                  collectBillingInfo={collectBillingInfo}
                  paymentType={paymentType}
                  placeOrder={placeOrder}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  grandTotal={grandTotal}
                />
                // </Elements>
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <CheckoutOrderSummary
                idOfStore={idOfStore}
                orgId={orgId}
                summaryData={summaryData}
                data={imageData}
                selected={selected}
                grandTotal={grandTotal}
                setGrandTotal={setGrandTotal}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={orderCreateLoading}
      >
        <CircularProgress
          color="primary"
          size={60}
          className={styles.loaderColor}
        />
      </Backdrop>
    </>
  );
};

export default CheckoutCart;
